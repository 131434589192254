import {
  ConditionGenderAge,
  ConditionGenderAgeFilter,
  ConditionGenderAgeFilterOpEnum,
  ConditionFamilyGenderAge,
  ConditionFamilyGenderAgeFilter,
  ConditionFamilyGenderAgeFilterOpEnum,
  CustomTargetBasicCondition
} from '@/api/openapi';

export const initBasicCondition = (): CustomTargetBasicCondition => {
  const basicTargetIds: number[] = [];
  const genderAges: ConditionGenderAge[] = [initGenderAges()];

  const occupation: number[] = [0];
  let married;
  const familyGenderAges: ConditionFamilyGenderAge[] = [initFamilyGenderAges()];
  return {
    basicTargetIds,
    genderAges,
    occupation,
    married,
    familyGenderAges
  };
};

export const initGenderAges = (): ConditionGenderAge => {
  const values: ConditionGenderAgeFilter[] = [];
  const genderEqual: ConditionGenderAgeFilter = {
    field: 'gender',
    op: ConditionGenderAgeFilterOpEnum.Equal,
    value: ''
  };
  values.push(genderEqual);
  const ageGreaterThen: ConditionGenderAgeFilter = {
    field: 'age',
    op: ConditionGenderAgeFilterOpEnum.GreaterThanOrEqual,
    value: ''
  };
  values.push(ageGreaterThen);
  const ageLessThen: ConditionGenderAgeFilter = {
    field: 'age',
    op: ConditionGenderAgeFilterOpEnum.LessThanOrEqual,
    value: ''
  };
  values.push(ageLessThen);
  return {
    genderAgeFilters: values
  };
};

export const initFamilyGenderAges = (): ConditionFamilyGenderAge => {
  const values: ConditionFamilyGenderAgeFilter[] = [];
  const genderEqual: ConditionFamilyGenderAgeFilter = {
    field: 'familyGender',
    op: ConditionFamilyGenderAgeFilterOpEnum.Equal,
    value: ''
  };
  values.push(genderEqual);
  const ageGreaterThen: ConditionFamilyGenderAgeFilter = {
    field: 'familyAge',
    op: ConditionFamilyGenderAgeFilterOpEnum.GreaterThanOrEqual,
    value: ''
  };
  values.push(ageGreaterThen);
  const ageLessThen: ConditionFamilyGenderAgeFilter = {
    field: 'familyAge',
    op: ConditionFamilyGenderAgeFilterOpEnum.LessThanOrEqual,
    value: ''
  };
  values.push(ageLessThen);
  return {
    familyGenderAgeFilters: values
  };
};
export const convertToConditionFamilyGenderAges = (
  values: ConditionFamilyGenderAge[]
): ConditionFamilyGenderAge[] => {
  return values.map(v => convertToConditionFamilyGenderAge(v));
};
const convertToConditionFamilyGenderAge = (
  baseValue: ConditionFamilyGenderAge
): ConditionFamilyGenderAge => {
  let genderEqual: ConditionFamilyGenderAgeFilter = {
    field: 'familyGender',
    op: ConditionFamilyGenderAgeFilterOpEnum.Equal,
    value: ''
  };
  let ageGreaterThen: ConditionFamilyGenderAgeFilter = {
    field: 'familyAge',
    op: ConditionFamilyGenderAgeFilterOpEnum.GreaterThanOrEqual,
    value: ''
  };
  let ageLessThen: ConditionFamilyGenderAgeFilter = {
    field: 'familyAge',
    op: ConditionFamilyGenderAgeFilterOpEnum.LessThanOrEqual,
    value: ''
  };
  baseValue.familyGenderAgeFilters.map(genderAge => {
    switch (genderAge.op) {
      case ConditionFamilyGenderAgeFilterOpEnum.Equal:
        genderEqual = genderAge;
        return;
      case ConditionFamilyGenderAgeFilterOpEnum.GreaterThanOrEqual:
        ageGreaterThen = genderAge;
        return;
      case ConditionFamilyGenderAgeFilterOpEnum.LessThanOrEqual:
        ageLessThen = genderAge;
        return;
      default:
        return;
    }
  });
  const convertedValue: ConditionFamilyGenderAgeFilter[] = [];
  convertedValue.push(genderEqual);
  convertedValue.push(ageGreaterThen);
  convertedValue.push(ageLessThen);
  return {
    familyGenderAgeFilters: convertedValue
  };
};

export const convertToConditionGenderAges = (
  values: ConditionGenderAge[]
): ConditionGenderAge[] => {
  return values.map(v => convertToConditionGenderAge(v));
};

const convertToConditionGenderAge = (
  baseValue: ConditionGenderAge
): ConditionGenderAge => {
  let genderEqual: ConditionGenderAgeFilter = {
    field: 'gender',
    op: ConditionGenderAgeFilterOpEnum.Equal,
    value: ''
  };
  let ageGreaterThen: ConditionGenderAgeFilter = {
    field: 'age',
    op: ConditionGenderAgeFilterOpEnum.GreaterThanOrEqual,
    value: ''
  };
  let ageLessThen: ConditionGenderAgeFilter = {
    field: 'age',
    op: ConditionGenderAgeFilterOpEnum.LessThanOrEqual,
    value: ''
  };
  baseValue.genderAgeFilters.map(genderAge => {
    switch (genderAge.op) {
      case ConditionGenderAgeFilterOpEnum.Equal:
        genderEqual = genderAge;
        return;
      case ConditionGenderAgeFilterOpEnum.GreaterThanOrEqual:
        ageGreaterThen = genderAge;
        return;
      case ConditionGenderAgeFilterOpEnum.LessThanOrEqual:
        ageLessThen = genderAge;
        return;
      default:
        return;
    }
  });
  const convertedValue: ConditionGenderAgeFilter[] = [];
  convertedValue.push(genderEqual);
  convertedValue.push(ageGreaterThen);
  convertedValue.push(ageLessThen);
  return {
    genderAgeFilters: convertedValue
  };
};
