import {
  ConditionCard,
  CustomTargetPurchaseSegmentConsumerClassConsumerClassEnum as consumerType
} from '@/api/openapi';

export { consumerType };

// 表示用アンケートモデル
export type EnqueteVModel = {
  id: number;
  title: string;
  questions: EnqueteQuestion[];
};

export type EnqueteQuestion = {
  id: number;
  title: string;
  topics: EnqueteQuestionTopic[];
};

export type EnqueteQuestionTopic = {
  id: string;
  multiple: boolean;
  title: string;
  options: EnqueteQuestionOption[];
};

export type EnqueteQuestionOption = {
  id: string;
  label?: string;
  checked: boolean;
  not?: boolean;
  invalid?: boolean;
};

// 表示用購買ログモデル
export type PurchaseVModel = {
  segmentCode: string;
  title: string;
  middleClasses: PurchaseMiddleClass[];
};

export type PurchaseMiddleClass = {
  segmentCode: string;
  title: string;
  minorClasses: PurchaseMinorClass[];
};

export type PurchaseMinorClass = {
  segmentCode: string;
  title: string;
  purchaseAmountHigher: number;
  purchaseAmountMedium: number;
  consumerClasses: consumer[];
};

export type consumer = {
  consumerClass: consumerType;
  checked: boolean;
  invalid?: boolean;
};

// [表示用] 条件カード
export type ConditionCardWithKey = {
  key: string;
  enqueteVModel: EnqueteVModel[];
  purchaseVModel: PurchaseVModel[];
} & ConditionCard;

export interface Tag {
  id: string;
  label: string;
  tags: TagValue[];
}

export interface TagValue {
  label: string;
  not?: boolean;
}
