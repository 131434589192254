import { AreaApi, BasicTargetApi, CompanyApi, CustomTargetApi } from '@/api';
import {
  Account,
  ConditionGenderAgeFilter,
  ConditionFamilyGenderAgeFilter,
  CustomTargetForm,
  CustomTargetNumberOfSamplesForm
} from '@/api/openapi';
import { BASIC_TARGET_DIVISION } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import { fetchAccountAvatar } from '@/composables/fetchImage';
import useLoading from '@/composables/loading';
import { CUSTOM_ROUTES } from '@/router';
import { useBasicTargetsStore } from '@/store/basicTargets';
import { useCustomTargetStore } from '@/store/customTarget/';
import { useUserInfoStore } from '@/store/userInfo';
import Axios from 'axios';
import { storeToRefs } from 'pinia';
import { Ref, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useModal from './modal';

interface UseCustomTargetPanelsReturnType {
  numberOfPersonalSamples: Ref<number>;
  numberOfHouseholdSamples: Ref<number>;
  calculationDate: Ref<string>;
  selectValue: Ref<{ id: string; label: string } | undefined>;
  options: Ref<{ id: string; label: string }[]>;
  headers: Ref<{ id: string; label: string; width?: string }[]>;
  items: Ref<{ [headerId: string]: string | undefined }[] | undefined>;
  isLoadingBasicTarget: Ref<boolean>;
  isLoadingCustomTargets: Ref<boolean>;
  isLoadingAreas: Ref<boolean>;
  onChangeDataList: (target: { id: string; label: string }) => void;
}

const getWorkspaceId = async (currentCompanyId?: string) => {
  const companyId = currentCompanyId ?? useRoute().params.companyId.toString();
  const userInfoStore = useUserInfoStore();
  if (userInfoStore.joinableCompanies.length === 0) {
    await userInfoStore.fetchJoinableCompanies();
  }
  const companyInfo = userInfoStore.getCompanyInfo(companyId);
  return companyInfo?.workspaceId ?? 0;
};

export const useCustomTargetPanels = (
  companyId: number
): UseCustomTargetPanelsReturnType => {
  const basicTargetStore = useBasicTargetsStore();
  const { basicTargets } = storeToRefs(basicTargetStore);
  const selectValue = ref<{ id: string; label: string }>();
  const options = ref<{ id: string; label: string }[]>([]);
  // 個人
  const numberOfPersonalSamples = ref<number>(0);
  // 世帯
  const numberOfHouseholdSamples = ref<number>(0);
  // 現在
  const calculationDate = ref<string>('');
  const basicTargetHeaders = [
    {
      id: 'custom',
      label: '区分',
      width: '25%'
    },
    {
      id: 'age',
      label: '年齢',
      width: '25%'
    },
    {
      id: 'gender',
      label: '性別',
      width: '25%'
    },
    {
      id: 'sample',
      label: 'サンプル',
      width: '25%'
    }
  ];
  // DataList の headers
  const headers = ref<{ id: string; label: string; width?: string }[]>(
    basicTargetHeaders
  );
  // DataList の items
  const items = ref<{ [headerId: string]: string | undefined }[] | undefined>(
    []
  );
  const route = useRoute();
  const currentCompanyId = Number(route.params.companyId);

  // カスタムターゲットのデータを取得
  const _getCustomTargets = async () => {
    if (currentCompanyId) {
      const result = await CustomTargetApi.getCompaniesCompanyIdCustomTargetsMonitorStructure(
        Number(currentCompanyId)
      );
      const data = result.data;

      numberOfPersonalSamples.value = data.numberOfPersonalSamples || 0;
      numberOfHouseholdSamples.value = data.numberOfHouseholdSamples || 0;
      calculationDate.value = data.calculationDate || '';

      const _headers = [
        { id: 'kanto', label: '関東', width: '8.3%' },
        { id: 'kansai', label: '関西', width: '8.3%' },
        { id: 'chukyo', label: '中京', width: '8.3%' },
        { id: 'hokkaido', label: '北海道', width: '8.3%' },
        { id: 'tohoku', label: '東北', width: '8.3%' },
        { id: 'hokukoushin', label: '北陸・甲信越', width: '8.3%' },
        { id: 'chushi', label: '中国・四国', width: '8.3%' },
        { id: 'kyushu', label: '九州・沖縄', width: '8.3%' },
        { id: 'miyagi', label: '宮城', width: '8.3%' },
        { id: 'shizuoka', label: '静岡', width: '8.3%' },
        { id: 'hiroshima', label: '広島', width: '8.3%' },
        { id: 'fukuoka', label: '福岡', width: '8.3%' }
      ];
      headers.value = [{ id: 'target', label: 'ターゲット' }];
      if (data.list && data.list.length > 0) {
        const custom = data.list[0];
        const entries = Object.entries(custom.numberOfSamples);
        const filteredKeys = entries
          .filter(entry => entry[1] !== null)
          .map(entry => entry[0]);
        const _contracted = _headers.filter(h => filteredKeys.includes(h.id));
        headers.value.push(..._contracted);
      }

      items.value = data.list?.map(customTarget => {
        const zenkoku =
          customTarget.numberOfSamples?.zenkoku?.toLocaleString() || '';
        const kanto =
          customTarget.numberOfSamples?.kanto?.toLocaleString() || '';
        const kansai =
          customTarget.numberOfSamples?.kansai?.toLocaleString() || '';
        const chukyo =
          customTarget.numberOfSamples?.chukyo?.toLocaleString() || '';
        const fukuoka =
          customTarget.numberOfSamples?.fukuoka?.toLocaleString() || '';
        // 中国・四国
        const chushi =
          customTarget.numberOfSamples?.chushi?.toLocaleString() || '';
        const hiroshima =
          customTarget.numberOfSamples?.hiroshima?.toLocaleString() || '';
        const hokkaido =
          customTarget.numberOfSamples?.hokkaido?.toLocaleString() || '';
        // 北陸・甲信越
        const hokukoushin =
          customTarget.numberOfSamples?.hokukoushin?.toLocaleString() || '';
        const kyushu =
          customTarget.numberOfSamples?.kyushu?.toLocaleString() || '';
        const miyagi =
          customTarget.numberOfSamples?.miyagi?.toLocaleString() || '';
        const shizuoka =
          customTarget.numberOfSamples?.shizuoka?.toLocaleString() || '';
        const tohoku =
          customTarget.numberOfSamples?.tohoku?.toLocaleString() || '';

        return {
          target: customTarget.name,
          zenkoku,
          kanto,
          kansai,
          chukyo,
          hokkaido,
          tohoku,
          hokukoushin,
          chushi,
          kyushu,
          miyagi,
          shizuoka,
          hiroshima,
          fukuoka
        };
      });
    }
  };
  const [isLoadingCustomTargets, getCustomTargets] = useLoading(
    _getCustomTargets
  );
  // 性・年齢12区分・男女10歳区分 のデータを取得
  const _getBasicTargets = async (
    basicDivisionType?: 'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC'
  ) => {
    if (!basicDivisionType) return;
    const result = await BasicTargetApi.getCompaniesCompanyIdBasicTargetsMonitorStructure(
      companyId,
      basicDivisionType
    );
    const data = result.data;

    numberOfPersonalSamples.value = data.numberOfPersonalSamples || 0;
    numberOfHouseholdSamples.value = data.numberOfHouseholdSamples || 0;
    calculationDate.value = data.calculationDate || '';
    headers.value = basicTargetHeaders;
    items.value = data.list?.map(data => ({
      custom: data.targetName,
      age: data.age,
      gender: data.gender,
      sample: data.numberOfSamples?.toLocaleString()
    }));
  };
  const [isLoadingBasicTarget, getBasicTargets] = useLoading(_getBasicTargets);

  // エリアのデータを取得
  const _getAreas = async () => {
    const result = await AreaApi.getCompaniesCompanyIdAreasMonitorStructure(
      companyId
    );
    const data = result.data;
    numberOfPersonalSamples.value = data.numberOfPersonalSamples || 0;
    numberOfHouseholdSamples.value = data.numberOfHouseholdSamples || 0;
    calculationDate.value = data.calculationDate || '';
    headers.value = [
      { id: 'custom', label: '区分' },
      { id: 'prefectures', label: '都道府県' },
      { id: 'personalSamples', label: '個人' },
      { id: 'householdSamples', label: '世帯' }
    ];
    items.value = data.list?.map(area => ({
      custom: area.name || '',
      prefectures: area.prefectures || '',
      personalSamples: area.numberOfPersonalSamples?.toLocaleString() || '',
      householdSamples: area.numberOfHouseholdSamples?.toLocaleString() || ''
    }));
  };
  const [isLoadingAreas, getAreas] = useLoading(_getAreas);

  const onChangeDataList = (target: { id: string; label: string }) => {
    if (target.id === BASIC_TARGET_DIVISION.ga8) {
      // '性／年齢８区分' のデータを取得 & 更新
      getBasicTargets(BASIC_TARGET_DIVISION.ga8);
    } else if (target.id === BASIC_TARGET_DIVISION.ga12) {
      // '性／年齢12区分' のデータを取得 & 更新
      getBasicTargets(BASIC_TARGET_DIVISION.ga12);
    } else if (target.id === BASIC_TARGET_DIVISION.ga10s) {
      // '男女１０歳区分' のデータを取得 & 更新
      getBasicTargets(BASIC_TARGET_DIVISION.ga10s);
    } else if (target.id === 'area') {
      // area: 'エリア' のデータを取得 & 更新
      getAreas(undefined);
    } else {
      // customTarget: 'カスタムターゲット' のデータを取得 & 更新
      getCustomTargets(undefined);
    }
  };

  // 初期表示
  headers.value = basicTargetHeaders;
  getBasicTargets('GA12');
  watchEffect(() => {
    options.value = [
      ...basicTargets.value.map((basicTarget, index) => ({
        id: basicTarget.divisionName || `basicTarget-${index}`,
        label: basicTarget.displayDivisionName || ''
      })),
      { id: 'area', label: 'エリア' },
      { id: 'customTargets', label: 'カスタムターゲット' }
    ];
    selectValue.value = options.value.find(
      option => option.id === BASIC_TARGET_DIVISION.ga12
    );
  });

  return {
    selectValue,
    options,
    numberOfPersonalSamples,
    numberOfHouseholdSamples,
    calculationDate,
    headers,
    items,
    isLoadingCustomTargets,
    isLoadingBasicTarget,
    isLoadingAreas,
    onChangeDataList
  };
};

interface UseRemoveCustomReturnType {
  isRemoveModalOpen: Ref<boolean>;
  isRemovingCustomTarget: Ref<boolean>;
  removedCustomTargetName: Ref<string>;
  openRemoveModal: (customId: number, name: string) => Promise<void>;
  removeCustom: (params: undefined) => Promise<void>;
}

export const useRemoveCustom = (): UseRemoveCustomReturnType => {
  const removedCustomId = ref(0);
  const removedCustomTargetName = ref('');
  const { isModalOpen, openModal, closeModal } = useModal();

  const openRemoveModal = async (customId: number, name: string) => {
    removedCustomTargetName.value = name;
    removedCustomId.value = customId;
    openModal();
  };

  const _removeCustom = async () => {
    await CustomTargetApi.deleteCustomTargetsCustomTargetId(
      removedCustomId.value
    );
    closeModal();
  };
  const [isRemovingCustomTarget, removeCustom] = useLoading(_removeCustom);

  return {
    isRemoveModalOpen: isModalOpen,
    removedCustomTargetName,
    isRemovingCustomTarget,
    openRemoveModal,
    removeCustom
  };
};

interface UseCheckSampleReturnType {
  isLoadingSample: Ref<boolean>;
  checkSample: (params: CustomTargetNumberOfSamplesForm) => Promise<void>;
}

export const useCheckSample = (companyId: number): UseCheckSampleReturnType => {
  const store = useCustomTargetStore();
  const _checkSample = async (params: CustomTargetNumberOfSamplesForm) => {
    const hasEmptyCard = store.conditions.some(condition =>
      store.isEmpty(condition.key)
    );
    const hasSingleAnswerCard = store.isAndDisabledList.some(
      list => list.Op === 'AND' && list.isAndDisabled === true
    );
    if (!store.isValid) {
      toast({ title: '入力値に不正があります', variant: 'error' });
      return;
    }
    if (hasSingleAnswerCard || store.isInvalidCard) {
      toast({ title: 'エラー状態の条件カードがあります', variant: 'error' });
      return;
    }
    if (hasEmptyCard) {
      toast({ title: '未設定の条件カードがあります', variant: 'error' });
      return;
    }
    const data = await (
      await CustomTargetApi.postCompaniesCompanyIdCustomTargetsNumberOfSamples(
        companyId,
        params
      )
    ).data;
    store.setSample(data.initialAreaSamples, data.contractAreaSamples);
  };
  const [isLoadingSample, checkSample] = useLoading(_checkSample);

  return {
    isLoadingSample,
    checkSample
  };
};

interface UseCustomTargetReturnType {
  isPostingCustomTarget: Ref<boolean>;
  isPuttingCustomTarget: Ref<boolean>;
  postCustomTarget: (params: CustomTargetForm) => Promise<void>;
  putCustomTarget: (params: {
    customTargetId: string;
    customTargetForm?: CustomTargetForm | undefined;
  }) => Promise<void>;
}

export const useCustomTarget = (): UseCustomTargetReturnType => {
  const store = useCustomTargetStore();
  const router = useRouter();
  const {
    params: { id, companyId }
  } = useRoute();
  const _postCustomTarget = async (params: CustomTargetForm) => {
    if (!companyId) {
      toast({
        title: '失敗しました',
        message: 'カンパニーIDがありません',
        variant: 'error'
      });
      return;
    }
    try {
      const result = await CustomTargetApi.postCompaniesCompanyIdCustomTargets(
        Number(companyId),
        params
      );
      if (200 <= result.status && result.status < 300) {
        store.setCustomTarget(result.data);
        await router.push({
          name: CUSTOM_ROUTES.completed,
          params: { id, companyId }
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e);
      } else {
        toast({
          title: '失敗',
          message: 'カスタムターゲットの作成に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isPostingCustomTarget, postCustomTarget] = useLoading(
    _postCustomTarget
  );

  const _putCustomTarget = async ({
    customTargetId,
    customTargetForm
  }: {
    customTargetId: string;
    customTargetForm?: CustomTargetForm;
  }) => {
    try {
      const result = await CustomTargetApi.putCustomTargetsCustomTargetId(
        Number(customTargetId),
        customTargetForm
      );
      if (200 <= result.status && result.status < 300) {
        store.setCustomTarget(result.data);
        await router.push({
          name: CUSTOM_ROUTES.completed,
          params: { id, companyId }
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e);
      } else {
        toast({
          title: '失敗',
          message: 'カスタムターゲットの作成に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isPuttingCustomTarget, putCustomTarget] = useLoading(_putCustomTarget);

  return {
    isPostingCustomTarget,
    postCustomTarget,
    isPuttingCustomTarget,
    putCustomTarget
  };
};

interface UseSearchReturnType {
  isLoadingTargetCategories: Ref<boolean>;
  getTargetCategories: (value?: {
    currentCompanyId: string;
    questionCategoryId?: number;
    keyword?: string;
  }) => Promise<void>;
}

export const useCategories = (): UseSearchReturnType | undefined => {
  const store = useCustomTargetStore();
  const _getTargetCategories = async (value?: {
    currentCompanyId: string;
    questionCategoryId?: number;
    keyword?: string;
    type?: string;
  }) => {
    const workspaceId = await getWorkspaceId(value?.currentCompanyId);
    if (workspaceId === 0) {
      return;
    }
    const result = await CustomTargetApi.getEnquetesSearch(
      workspaceId,
      value?.questionCategoryId,
      value?.keyword
    );
    store.setCategoriesList(
      result.data.enqueteSearchResult,
      result.data.purchaseLogSearchResult,
      value?.type
    );
  };
  const [isLoadingTargetCategories, getTargetCategories] = useLoading(
    _getTargetCategories
  );

  return {
    isLoadingTargetCategories,
    getTargetCategories
  };
};

interface UseCustomTargetTagReturnType {
  generateGenderAgeTag: (filters: ConditionGenderAgeFilter[]) => string;
  generateFamilyGenderAgeTag: (
    filters: ConditionFamilyGenderAgeFilter[]
  ) => string;
}

export const useCustomTargetTag = (): UseCustomTargetTagReturnType => {
  const generateGenderAgeTag = (
    filters: ConditionGenderAgeFilter[]
  ): string => {
    let label = '';
    const _gender = filters.find(
      filter => 'field' in filter && filter.field === 'gender'
    ) as ConditionGenderAgeFilter;
    label += _gender?.value ? (_gender.value === 'f' ? '女性' : '男性') : '';
    const _from = filters.find(
      filter => filter.op === 'GREATER_THAN_OR_EQUAL'
    ) as ConditionGenderAgeFilter;
    const _to = filters.find(
      filter => filter.op === 'LESS_THAN_OR_EQUAL'
    ) as ConditionGenderAgeFilter;
    if (_from && _to) {
      label += `${_gender?.value ? '：' : ''}${_from.value}〜${_to.value}歳`;
    }
    return label;
  };
  const generateFamilyGenderAgeTag = (
    filters: ConditionFamilyGenderAgeFilter[]
  ): string => {
    let label = '';
    const _gender = filters.find(
      filter => 'field' in filter && filter.field === 'familyGender'
    ) as ConditionFamilyGenderAgeFilter;
    label += _gender?.value ? (_gender.value === 'f' ? '女性' : '男性') : '';
    const _from = filters.find(
      filter => filter.op === 'GREATER_THAN_OR_EQUAL'
    ) as ConditionFamilyGenderAgeFilter;
    const _to = filters.find(
      filter => filter.op === 'LESS_THAN_OR_EQUAL'
    ) as ConditionFamilyGenderAgeFilter;
    if (_from && _to) {
      label += `${_gender?.value ? '：' : ''}${_from.value}〜${_to.value}歳`;
    }
    return label;
  };
  return {
    generateGenderAgeTag,
    generateFamilyGenderAgeTag
  };
};

interface UseCustomTargetsReturnType {
  accounts: Ref<Account[]>;
  accountImages: Ref<{ id: number; imageUrl: string | null }[]>;
  isLoadingCustomTargets: Ref<boolean>;
  isLoadingCategoriesList: Ref<boolean>;
  getCustomTargets: (limit?: number, offset?: number) => Promise<void>;
  getCategoriesList: (params: undefined) => Promise<void>;
}

export const useCustomTargets = (): UseCustomTargetsReturnType | undefined => {
  const route = useRoute();
  const currentCompanyId = Number(route.params.companyId);
  const accounts = ref<Account[]>([]);
  const accountImages = ref<{ id: number; imageUrl: string | null }[]>([]);
  const store = useCustomTargetStore();

  const _getCustomTargets = async (limit?: number, offset?: number) => {
    if (currentCompanyId) {
      const result = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
        currentCompanyId,
        limit,
        offset
      );
      const customTargets = result.data.list;
      store.setCustomTargetList(customTargets);
      if (customTargets) {
        getAccounts();
        for (const customTarget of customTargets) {
          getAccountImage(customTarget.authorAccountId);
        }
      }
    }
  };
  const [isLoadingCustomTargets, getCustomTargets] = useLoading(
    _getCustomTargets
  );

  const getAccounts = async () => {
    if (currentCompanyId) {
      const result = await CompanyApi.getCompaniesCompanyIdAccountsSearch(
        currentCompanyId
      );
      accounts.value =
        result.data.list?.map(account => ({
          ...account,
          name: account?.nickname || `${account?.lastName}${account?.firstName}`
        })) ?? [];
    }
  };

  const getAccountImage = async (accountId?: number) => {
    if (!accountId) return null;
    fetchAccountAvatar(accountId.toString()).then(
      imageUrl =>
        (accountImages.value = [
          ...accountImages.value,
          { id: accountId, imageUrl }
        ])
    );
  };

  const _getCategoriesList = async () => {
    const workspaceId = await getWorkspaceId();
    if (workspaceId === 0) {
      return;
    }
    const result = await CustomTargetApi.getEnquetesSearch(workspaceId);
    store.setCategoriesList(
      result.data.enqueteSearchResult,
      result.data.purchaseLogSearchResult
    );
  };

  const [isLoadingCategoriesList, getCategoriesList] = useLoading(
    _getCategoriesList
  );

  return {
    accounts,
    accountImages,
    isLoadingCustomTargets,
    isLoadingCategoriesList,
    getCategoriesList,
    getCustomTargets
  };
};

interface UseCustomTargetEditReturnType {
  isLoadingDataEditing: Ref<boolean>;
  getDataEditing: (customTargetId: string) => Promise<void>;
}

export const useCustomTargetEdit = ():
  | UseCustomTargetEditReturnType
  | undefined => {
  const store = useCustomTargetStore();

  const _getCustomTarget = async (customTargetId: string) => {
    const workspaceId = await getWorkspaceId();
    if (workspaceId === 0) {
      return;
    }
    const result = await Promise.all([
      CustomTargetApi.getCustomTargetsCustomTargetId(
        Number(customTargetId)
      ).then(data => data.data),
      CustomTargetApi.getEnquetesSearch(workspaceId).then(data => data.data)
    ]);
    const [customTarget, data] = result;
    store.setCustomtargetForEditing(
      customTarget,
      data.enqueteSearchResult,
      data.purchaseLogSearchResult
    );
  };
  const [isLoadingDataEditing, getDataEditing] = useLoading(_getCustomTarget);

  return {
    isLoadingDataEditing,
    getDataEditing
  };
};
