import {
  CustomTargetPurchaseCondition,
  CustomTargetPurchaseSegment,
  CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum,
  PurchaseLogLargeCategory
} from '@/api/openapi';

import { consumerType, PurchaseVModel } from './type';

export const initPurchaseVModel = (
  purchaseLogLargeCategories?: PurchaseLogLargeCategory[]
): PurchaseVModel[] => {
  return (
    purchaseLogLargeCategories?.map(large => ({
      segmentCode: large.segmentCode,
      title: large.segmentName,
      middleClasses: large.middleClasses?.map(middle => ({
        segmentCode: middle.segmentCode,
        title: middle.segmentName,
        minorClasses: middle.minorClasses?.map(minor => ({
          segmentCode: minor.segmentCode,
          title: minor.segmentName,
          purchaseAmountHigher: minor.purchaseAmountHigher,
          purchaseAmountMedium: minor.purchaseAmountMedium,
          consumerClasses: Object.values(consumerType).map(consumersKey => ({
            consumerClass: consumersKey as consumerType,
            checked: false
          }))
        }))
      }))
    })) || []
  );
};

export const updatePurchaseVModel = (
  purchaseVModel: PurchaseVModel[],
  value: {
    largeSegmentCode?: string;
    middleSegmentCode?: string;
    minorSegmentCode?: string;
    consumerKey?: consumerType;
    checked: boolean;
  }
): PurchaseVModel[] => {
  return purchaseVModel.map(category => ({
    ...category,
    middleClasses:
      category.segmentCode === value.largeSegmentCode
        ? category.middleClasses.map(middle => ({
            ...middle,
            minorClasses:
              middle.segmentCode === value.middleSegmentCode
                ? middle.minorClasses.map(minor => ({
                    ...minor,
                    consumerClasses:
                      minor.segmentCode === value.minorSegmentCode
                        ? minor.consumerClasses.map(consumer => ({
                            ...consumer,
                            checked:
                              consumer.consumerClass === value.consumerKey
                                ? value.checked
                                : consumer.checked
                          }))
                        : minor.consumerClasses
                  }))
                : middle.minorClasses
          }))
        : category.middleClasses
  }));
};

export const convertToPurchaseVModel = (
  purchaseCondition?: CustomTargetPurchaseCondition | null,
  purchaseLogLargeCategories?: PurchaseLogLargeCategory[]
): PurchaseVModel[] => {
  if (!purchaseCondition) return initPurchaseVModel(purchaseLogLargeCategories);
  const filters = purchaseCondition.purchaseSegments;
  const basePurchaseVModel =
    purchaseLogLargeCategories?.map(large => ({
      segmentCode: large.segmentCode,
      title: large.segmentName,
      middleClasses: large.middleClasses?.map(middle => ({
        segmentCode: middle.segmentCode,
        title: middle.segmentName,
        minorClasses: middle.minorClasses?.map(minor => ({
          segmentCode: minor.segmentCode,
          title: minor.segmentName,
          purchaseAmountHigher: minor.purchaseAmountHigher,
          purchaseAmountMedium: minor.purchaseAmountMedium,
          consumerClasses: Object.values(consumerType).map(consumersKey => ({
            consumerClass: consumersKey as consumerType,
            checked: isCheckedPurchase(
              filters,
              minor.segmentCode,
              consumersKey as consumerType
            )
          }))
        }))
      }))
    })) || [];

  // 利用不可購買ログを使用しているカスタムターゲット
  const invalidPurchase = getInvalidCustomTargetPurchase(
    purchaseCondition,
    purchaseLogLargeCategories
  );

  return concatEnqueteVModel(basePurchaseVModel, invalidPurchase);
};

export const initPurchaseCondition = (
  purchaseKey?: string
): CustomTargetPurchaseCondition => {
  return {
    purchaseKey: purchaseKey ?? '',
    purchaseSegments: []
  };
};

export const isCheckedPurchase = (
  filters: CustomTargetPurchaseSegment[],
  segmentCode: string,
  consumersKey: consumerType
): boolean => {
  if (!segmentCode) return false;
  return filters
    .filter(purchase => purchase.minorClassSegmentCode === segmentCode)
    .some(purchase => {
      return purchase.consumerClasses.some(consumer => {
        return consumer.consumerClass === consumersKey;
      });
    });
};

export const makePurchaseLablel = (
  consumersKey: consumerType,
  purchaseAmountHigher: number,
  purchaseAmountMedium: number
): string => {
  switch (consumersKey) {
    case consumerType.HeavyUser:
      return purchaseAmountHigher.toLocaleString() + '円以上／年';
    case consumerType.MiddleUser:
      return (
        purchaseAmountMedium.toLocaleString() +
        '円〜' +
        purchaseAmountHigher.toLocaleString() +
        '円未満／年'
      );
    case consumerType.LightUser:
      return purchaseAmountMedium.toLocaleString() + '円未満／年';
    case consumerType.NonUser:
      return '0円／年';
    default:
      return '';
  }
};

export const makePurchaseCardLablel = (consumersKey: consumerType): string => {
  switch (consumersKey) {
    case consumerType.HeavyUser:
      return 'ヘビー';
    case consumerType.MiddleUser:
      return 'ミドル';
    case consumerType.LightUser:
      return 'ライト';
    case consumerType.NonUser:
      return 'ノン';
    default:
      return '';
  }
};

export const getPurchaseIconName = (consumersKey: consumerType): string => {
  switch (consumersKey) {
    case consumerType.HeavyUser:
      return 'user-heavy';
    case consumerType.MiddleUser:
      return 'user-middle';
    case consumerType.LightUser:
      return 'user-light';
    case consumerType.NonUser:
      return 'user-none';
    default:
      return '';
  }
};

type valueOf<T> = T[keyof T];
export const convertToConsumer = (
  consumerKey?: consumerType
): valueOf<typeof CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum> => {
  switch (consumerKey) {
    case consumerType.HeavyUser:
      return CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum.HeavyUser;
    case consumerType.MiddleUser:
      return CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum.MiddleUser;
    case consumerType.LightUser:
      return CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum.LightUser;
    case consumerType.NonUser:
      return CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum.NonUser;
    default:
      return CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum.NonUser;
  }
};

/* TODO 以下、利用不可な購買ログの表示対応 */
export const getInvalidCustomTargetPurchase = (
  purchaseCondition?: CustomTargetPurchaseCondition | null,
  purchaseLogLargeCategories?: PurchaseLogLargeCategory[]
): CustomTargetPurchaseSegment[] => {
  // カスタムターゲットに使用されている利用不可な購買ログ情報を抽出
  const invalidPurchase =
    purchaseCondition?.purchaseSegments.filter(targetPurchase =>
      checkPurchaseInvalid(
        targetPurchase.minorClassSegmentCode,
        purchaseLogLargeCategories
      )
    ) ?? [];

  return invalidPurchase.filter(p => p.consumerClasses.length > 0);
};

// 利用不可な購買ログの表示対応用
const checkPurchaseInvalid = (
  minorClassSegmentCode: string,
  purchaseLogLargeCategories?: PurchaseLogLargeCategory[]
): boolean => {
  return purchaseLogLargeCategories?.some(large =>
    large.middleClasses.some(middle =>
      middle.minorClasses.some(
        minor => minor.segmentCode === minorClassSegmentCode
      )
    )
  )
    ? false
    : true;
};

// 利用不可な購買ログの表示対応用
const concatEnqueteVModel = (
  base: PurchaseVModel[],
  invalidPurchase: CustomTargetPurchaseSegment[]
): PurchaseVModel[] => {
  if (invalidPurchase.length < 1) {
    return base;
  }

  invalidPurchase.map(invalid => {
    base.push(initInvalidEnqueteVModel(invalid));
  });

  return base;
};

// 利用不可な購買ログの表示対応用
const initInvalidEnqueteVModel = (
  invalidPurchase: CustomTargetPurchaseSegment
): PurchaseVModel => {
  return {
    segmentCode: '',
    title: '',
    middleClasses: [
      {
        segmentCode: '',
        title: '',
        minorClasses: [
          {
            segmentCode: invalidPurchase.minorClassSegmentCode,
            title: invalidPurchase.minorClassSegmentName ?? 'エラー条件',
            purchaseAmountHigher: 0,
            purchaseAmountMedium: 0,
            consumerClasses: invalidPurchase.consumerClasses.map(consumer => ({
              consumerClass: consumer.consumerClass,
              checked: true,
              invalid: true
            }))
          }
        ]
      }
    ]
  };
};
