/**
 * 【APIのドキュメント】
 *  https://www.notion.so/switchm/JSON-74a758a362cc4fa58f7bf4dd69ea166f#f363a712f59b41d89a03542aaf8d9ac6
 */
import {
  CustomTargetEnquete,
  CustomTargetEnqueteCondition,
  QuestionCategory
} from '@/api/openapi';
import { EnqueteVModel } from './type';

// 表示用アンケートデータ
export const initEnqueteVModel = (
  enquetes?: QuestionCategory[]
): EnqueteVModel[] => {
  return (
    enquetes?.map(enquete => ({
      id: enquete.questionCategoryId,
      title: enquete.categoryText,
      questions: enquete.questions?.map(question => ({
        id: question.questionId,
        title: question.questionText,
        topics: question.topics?.map(topic => ({
          id: topic.topicCode,
          multiple: topic.answerType === 'MULTIPLE',
          title: topic.topicText,
          options: topic.options?.map(option => ({
            id: option.optionCode,
            label: option.optionText,
            checked: false,
            not: topic.answerType === 'MULTIPLE' ? false : undefined
          }))
        }))
      }))
    })) || []
  );
};

export const updateEnqueteVModel = (
  enqueteVModel: EnqueteVModel[],
  value: {
    categoryId?: number;
    questionId?: number;
    topicCode?: string;
    optionCode?: string;
    checked: boolean;
    not?: boolean;
  }
): EnqueteVModel[] => {
  return enqueteVModel.map(category => ({
    ...category,
    questions:
      category.id === value.categoryId
        ? category.questions.map(question => ({
            ...question,
            topics:
              question.id === value.questionId
                ? question.topics.map(topic => ({
                    ...topic,
                    options:
                      topic.id === value.topicCode
                        ? topic.options.map(option => ({
                            ...option,
                            checked:
                              option.id === value.optionCode
                                ? value.checked
                                : option.checked,
                            not:
                              option.id === value.optionCode
                                ? value.checked === false
                                  ? false
                                  : value.not || option.not
                                : option.not
                          }))
                        : topic.options
                  }))
                : question.topics
          }))
        : category.questions
  }));
};

export const convertToEnqueteVModel = (
  enqueteCondition?: CustomTargetEnqueteCondition | null,
  enquetes?: QuestionCategory[]
): EnqueteVModel[] => {
  if (!enqueteCondition) return initEnqueteVModel(enquetes);
  const filters = enqueteCondition.enquetes;
  const baseEnqueteVModel: EnqueteVModel[] =
    enquetes?.map(enquete => ({
      id: enquete.questionCategoryId,
      title: enquete.categoryText,
      questions: enquete.questions?.map(question => ({
        id: question.questionId,
        title: question.questionText,
        topics: question.topics.map(topic => ({
          id: topic.topicCode,
          multiple: topic.answerType === 'MULTIPLE',
          title: topic.topicText,
          options: topic.options.map(option => ({
            id: option.optionCode,
            label: option.optionText,
            checked:
              isCheckedEnquete(filters, topic.topicCode, option.optionCode) ||
              isNotEnquete(filters, topic.topicCode, option.optionCode),
            not:
              topic.answerType === 'MULTIPLE'
                ? isNotEnquete(filters, topic.topicCode, option.optionCode)
                : undefined
          }))
        }))
      }))
    })) || [];

  // 利用不可アンケートを使用しているカスタムターゲット
  const invalidEnquete = getInvalidCustomTargetEnquete(filters, enquetes);

  return concatEnqueteVModel(baseEnqueteVModel, invalidEnquete);
};

// アンケート条件データ
export const initEnqueteCondition = (
  enqueteKey?: string
): CustomTargetEnqueteCondition => {
  return {
    enqueteKey: enqueteKey ?? '',
    enquetes: []
  };
};

export const isCheckedEnquete = (
  filters: CustomTargetEnquete[],
  topicCode?: string,
  optionCode?: string
): boolean => {
  if (!topicCode || !optionCode) return false;

  return filters
    .filter(enquetes => enquetes.topicCode.value === topicCode)
    .some(enquetes => {
      return enquetes.optionCodes.some(
        optionCodeItem =>
          optionCodeItem.op !== 'NOT_IN' &&
          optionCodeItem.optionCodeValues.some(
            item => item.value === optionCode
          )
      );
    });
};

export const isNotEnquete = (
  filters: CustomTargetEnquete[],
  topicCode?: string,
  optionCode?: string
): boolean => {
  if (!topicCode || !optionCode) return false;

  return filters
    .filter(enquetes => enquetes.topicCode.value === topicCode)
    .some(enquetes => {
      return enquetes.optionCodes.some(
        optionCodeItem =>
          optionCodeItem.op === 'NOT_IN' &&
          optionCodeItem.optionCodeValues.some(
            item => item.value === optionCode
          )
      );
    });
};

/* TODO 以下、利用不可なアンケートの表示対応
   無駄な処理が多いため効率化したい
 */
export const getInvalidCustomTargetEnquete = (
  filters: CustomTargetEnquete[],
  enquetes?: QuestionCategory[]
): CustomTargetEnquete[] => {
  // 利用不可(QuestionCategoryに存在しない)アンケートを抽出
  const invalidCustomTargetEnquete: CustomTargetEnquete[] = filters
    .map(targetEnquete => ({
      ...targetEnquete,
      optionCodes: targetEnquete.optionCodes.map(option => ({
        ...option,
        optionCodeValues: option.optionCodeValues.filter(code =>
          checkEnqueteInvalid(code.value, enquetes)
        )
      }))
    }))
    .map(targetEnquete => ({
      ...targetEnquete,
      optionCodes: targetEnquete.optionCodes.filter(
        option => option.optionCodeValues.length > 0
      )
    }))
    .filter(targetEnquete => targetEnquete.optionCodes.length > 0);

  return invalidCustomTargetEnquete;
};

// 利用不可なアンケートの表示対応用
const checkEnqueteInvalid = (
  targetOptionCode: string,
  enquetes?: QuestionCategory[]
): boolean => {
  return enquetes?.some(category =>
    category.questions.some(question =>
      question.topics.some(topic =>
        topic.options.some(option => option.optionCode === targetOptionCode)
      )
    )
  )
    ? false
    : true;
};

// 利用不可なアンケートの表示対応用
const concatEnqueteVModel = (
  base: EnqueteVModel[],
  invalidEnquete: CustomTargetEnquete[]
): EnqueteVModel[] => {
  if (invalidEnquete.length < 1) {
    return base;
  }

  const validDataList: {
    categoryId: number;
    questionId: number;
    topicCode: string;
  }[] = [];
  base.map(base =>
    base.questions.map(question =>
      question.topics.map(topic => {
        validDataList.push({
          categoryId: base.id,
          questionId: question.id,
          topicCode: topic.id
        });
      })
    )
  );

  invalidEnquete.map(invalid => {
    const validData = validDataList.find(
      data => data.topicCode === invalid.topicCode.value
    );
    if (validData) {
      addInvalidEnqueteVModel(base, validData, invalid);
    } else {
      base.push(initInvalidEnqueteVModel(invalid));
    }
  });

  return base;
};

// 利用不可なアンケートの表示対応用
const initInvalidEnqueteVModel = (
  invalidEnquete: CustomTargetEnquete
): EnqueteVModel => {
  return {
    id: 0,
    title: '',
    questions: [
      {
        id: 0,
        title: '',
        topics: [
          {
            id: invalidEnquete.topicCode.value,
            multiple: true,
            title: invalidEnquete.topicCode.topicText ?? '',
            options: invalidEnquete.optionCodes.map(option => ({
              id: option.optionCodeValues[0].value ?? 'エラー条件',
              label: option.optionCodeValues[0].optionText ?? 'エラー条件',
              checked: true,
              not: undefined,
              invalid: true
            }))
          }
        ]
      }
    ]
  };
};

// 利用不可なアンケートの表示対応用
const addInvalidEnqueteVModel = (
  base: EnqueteVModel[],
  validData: {
    categoryId: number;
    questionId: number;
    topicCode: string;
  },
  invalidEnquete: CustomTargetEnquete
): EnqueteVModel[] => {
  return base.map(category => ({
    ...category,
    questions:
      category.id === validData.categoryId
        ? category.questions.map(question => ({
            ...question,
            topics:
              question.id === validData.questionId
                ? question.topics.map(topic => {
                    if (topic.id === invalidEnquete.topicCode.value) {
                      invalidEnquete.optionCodes.map(invalidOption => {
                        topic.options.push({
                          id: invalidOption.optionCodeValues[0].value,
                          label: invalidOption.optionCodeValues[0].optionText,
                          checked: true,
                          not: false,
                          invalid: true
                        });
                      });
                    }
                    return {
                      id: topic.id,
                      multiple: topic.multiple,
                      title: topic.title,
                      options: topic.options
                    };
                  })
                : question.topics
          }))
        : category.questions
  }));
};
