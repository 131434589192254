// api docs: https://www.notion.so/switchm/JSON-74a758a362cc4fa58f7bf4dd69ea166f
import { v4 as uuid } from 'uuid';

import {
  convertToConditionGenderAges,
  convertToConditionFamilyGenderAges,
  initBasicCondition,
  initGenderAges,
  initFamilyGenderAges
} from './basicCondition';

import {
  AreaSampleNumber,
  ConditionCardConditionOpEnum,
  ConditionCardForm,
  ConditionCardFormConditionOpEnum,
  ConditionCardOpEnum,
  ConditionGenderAge,
  ConditionGenderAgeFilter,
  ConditionGenderAgeFilterOpEnum,
  ConditionFamilyGenderAge,
  ConditionFamilyGenderAgeFilter,
  ConditionFamilyGenderAgeFilterOpEnum,
  CustomTarget,
  CustomTargetBasicCondition,
  CustomTargetEnqueteConditionForm,
  CustomTargetEnqueteTopicForm,
  CustomTargetPurchaseConditionForm,
  CustomTargetPurchaseSegmentForm,
  NumberOfSamplesInitialAreaSamples,
  // NumberOfSamplesList,
  PurchaseLogCategoryList,
  PurchaseLogLargeCategory,
  PurchaseLogMinorCategory,
  QuestionCategory,
  QuestionCategoryList,
  QuestionTopic
} from '@/api/openapi';
import { max } from 'lodash';
import { defineStore } from 'pinia';
import {
  convertToEnqueteVModel,
  initEnqueteCondition,
  initEnqueteVModel,
  updateEnqueteVModel
} from './enqueteCondition';
import {
  convertToConsumer,
  convertToPurchaseVModel,
  initPurchaseCondition,
  initPurchaseVModel,
  updatePurchaseVModel
} from './purchaseCondition';
import { completedConditionTags } from './tags';
import { getTooltipText } from './tooltipText';
import { ConditionCardWithKey, consumerType } from './type';

const initialConditionId = 0;
const initialConditionKey = uuid();
const initialCustomTargetId = 0;
export const initialEnqueteCategory = { id: 0, label: '全選択' };
export const initialPurchaseCategory = { id: 0, label: 'すべて' };

const initConditions = (
  key = initialConditionKey,
  op?: ConditionCardOpEnum
): ConditionCardWithKey => {
  return {
    key: key,
    id: initialConditionId,
    customTargetId: initialCustomTargetId,
    op,
    conditionOp: ConditionCardConditionOpEnum.Or,
    basicCondition: initBasicCondition(),
    enqueteCondition: undefined,
    purchaseCondition: undefined,
    enqueteVModel: initEnqueteVModel(),
    purchaseVModel: initPurchaseVModel()
  };
};
const smallSampleSize = 100;

export const useCustomTargetStore = defineStore('custom', {
  state: () => ({
    activeConditionKey: initialConditionKey,
    activeEnqueteCategory: initialEnqueteCategory,
    activePurchaseCategory: initialPurchaseCategory,
    conditions: [initConditions()] as ConditionCardWithKey[],
    customTarget: undefined as CustomTarget | undefined,
    customTargets: [] as CustomTarget[],
    questionCategoryList: undefined as QuestionCategoryList | undefined,
    questionCategoryFullList: undefined as QuestionCategoryList | undefined, // CustomTargetApi.getEnquetesSearch 全件
    enqueteKey: undefined as string | undefined,
    purchaseLogCategoryList: undefined as PurchaseLogCategoryList | undefined,
    purchaseLogCategoryFullList: undefined as
      | PurchaseLogCategoryList
      | undefined,
    purchaseKey: undefined as string | undefined,
    errorModal: { isShow: false, title: '', message: '' },
    checkedSample: false,
    isDisplaySample: false,
    sampleCount: '',
    isSmallSample: false,
    initialSamples: [] as Array<AreaSampleNumber>,
    contractedSamples: [] as Array<AreaSampleNumber>
  }),
  actions: {
    updateActiveConditionKey(conditionKey: string) {
      this.activeConditionKey = conditionKey;
      this.resetEnqueteCategory();
      this.resetPurchaseCategory();
    },
    addCondition(): string {
      const key = uuid();
      this.conditions = [
        ...this.conditions,
        {
          ...initConditions(key, ConditionCardOpEnum.And),
          enqueteVModel: initEnqueteVModel(this.questionCategoryFullList?.list),
          purchaseVModel: initPurchaseVModel(
            this.purchaseLogCategoryFullList?.list
          )
        }
      ];
      this.activeConditionKey = key;
      return key;
    },
    removeCondition(conditionKey: string) {
      if (this.activeConditionKey === conditionKey) {
        const conditionIndex = this.conditions.findIndex(
          condition => condition.key === conditionKey
        );
        this.activeConditionKey =
          this.conditions.find((_, index) => index === conditionIndex - 1)
            ?.key ||
          this.conditions.find((_, index) => index === conditionIndex + 1)
            ?.key ||
          this.activeConditionKey;
      }
      this.conditions = this.conditions.filter(
        condition => condition.key !== conditionKey
      );
      this.conditions = this.conditions.map((condition, index) => ({
        ...condition,
        op: index === 0 ? undefined : condition.op
      }));
    },
    setCustomTarget(customTarget: CustomTarget) {
      this.customTarget = customTarget;
    },
    setCustomTargetList(customTargets?: CustomTarget[]) {
      this.customTargets = customTargets || [];
    },
    setCategoriesList(
      questionCategoryList?: QuestionCategoryList,
      purchaseLogCategoryList?: PurchaseLogCategoryList,
      type = 'full'
    ) {
      if (purchaseLogCategoryList?.list) {
        purchaseLogCategoryList.list.sort(
          (a, b) => a.displayOrder - b.displayOrder
        );
        for (const large of purchaseLogCategoryList.list) {
          large.middleClasses.sort((a, b) => a.displayOrder - b.displayOrder);
          for (const middle of large.middleClasses) {
            middle.minorClasses.sort((a, b) => a.displayOrder - b.displayOrder);
          }
        }
      }

      if (type === 'full') {
        // type === 'full' ... 全件 type === 'search' ... 検索結果
        this.questionCategoryFullList = questionCategoryList;
        this.purchaseLogCategoryFullList = purchaseLogCategoryList;
      }
      this.questionCategoryList = questionCategoryList;
      this.purchaseLogCategoryList = purchaseLogCategoryList;

      this.enqueteKey = questionCategoryList?.enqueteKey
        ? questionCategoryList?.enqueteKey
        : this.enqueteKey;
      this.purchaseKey = purchaseLogCategoryList?.purchaseKey
        ? purchaseLogCategoryList?.purchaseKey
        : this.purchaseKey;
    },
    // 登録されているカスタムターゲットのデータを編集画面に反映する
    setCustomtargetForEditing(
      customTarget: CustomTarget,
      questionCategoryList?: QuestionCategoryList,
      purchaseLogCategoryList?: PurchaseLogCategoryList
    ) {
      this.customTarget = customTarget;
      this.setCategoriesList(questionCategoryList, purchaseLogCategoryList);

      if (!customTarget.conditionCards) return;
      if (!questionCategoryList) return;
      this.conditions = customTarget.conditionCards.map(condition => ({
        key: uuid(),
        id: condition.id,
        customTargetId: condition.customTargetId,
        op: condition.op,
        conditionOp: condition.conditionOp,
        basicCondition: condition.basicCondition
          ? {
              genderAges: condition.basicCondition.genderAges
                ? convertToConditionGenderAges(
                    condition.basicCondition.genderAges
                  )
                : [initGenderAges()],
              basicTargetIds: condition.basicCondition.basicTargetIds ?? [],
              occupation: condition.basicCondition.occupation ?? [0],
              married: condition.basicCondition.married ?? undefined,
              familyGenderAges: condition.basicCondition.familyGenderAges
                ? convertToConditionFamilyGenderAges(
                    condition.basicCondition.familyGenderAges
                  )
                : [initFamilyGenderAges()]
            }
          : initBasicCondition(),
        enqueteCondition: condition.enqueteCondition,
        purchaseCondition: condition.purchaseCondition,
        enqueteVModel: convertToEnqueteVModel(
          condition.enqueteCondition,
          questionCategoryList.list
        ),
        purchaseVModel: convertToPurchaseVModel(
          condition.purchaseCondition,
          purchaseLogCategoryList?.list
        )
      }));
      this.updateActiveConditionKey(this.conditions[0].key);
    },
    // カスタムターゲットを初期化
    resetCustomTarget() {
      this.conditions = [initConditions()];
      this.customTarget = undefined;
      this.activeConditionKey = initialConditionKey;
    },
    /**
     * 基本属性
     * ConditionCard.basicCondition
     * https://www.notion.so/switchm/JSON-74a758a362cc4fa58f7bf4dd69ea166f#ed2099742a6b4dc1ae489f1b11e3a175
     */
    updateBasicCondition(
      field: string,
      value:
        | boolean
        | (number | ConditionGenderAge | ConditionFamilyGenderAge)[]
        | undefined
    ) {
      this.conditions = this.conditions.map(condition =>
        condition.key === this.activeConditionKey
          ? {
              ...condition,
              basicCondition: {
                ...condition.basicCondition,
                [field]: value
              }
            }
          : condition
      );
    },
    // 基本属性（手動設定）追加
    addManualSetting() {
      const activeConditionKey = this.activeConditionKey;
      this.conditions = this.conditions.map(condition =>
        condition.key === activeConditionKey &&
        condition.basicCondition &&
        condition.basicCondition.genderAges
          ? {
              ...condition,
              basicCondition: {
                ...condition.basicCondition,
                genderAges: [
                  ...condition.basicCondition.genderAges,
                  initGenderAges()
                ]
              }
            }
          : condition
      );
    },
    // 基本属性（手動設定）削除
    removeManualSetting(index: number) {
      const activeConditionKey = this.activeConditionKey;
      this.conditions = this.conditions.map(condition =>
        condition.key === activeConditionKey &&
        condition.basicCondition &&
        condition.basicCondition.genderAges
          ? {
              ...condition,
              basicCondition: {
                ...condition.basicCondition,
                genderAges: condition.basicCondition.genderAges.filter(
                  (_: ConditionGenderAge, genderAgeIndex: number) =>
                    genderAgeIndex !== index
                )
              }
            }
          : condition
      );
    },

    // 同居家族の年齢追加
    addFamilySetting() {
      const activeConditionKey = this.activeConditionKey;
      this.conditions = this.conditions.map(condition =>
        condition.key === activeConditionKey &&
        condition.basicCondition &&
        condition.basicCondition.familyGenderAges
          ? {
              ...condition,
              basicCondition: {
                ...condition.basicCondition,
                familyGenderAges: [
                  ...condition.basicCondition.familyGenderAges,
                  initFamilyGenderAges()
                ]
              }
            }
          : condition
      );
    },
    // 同居家族の年齢削除
    removeFamilySetting(index: number) {
      const activeConditionKey = this.activeConditionKey;
      this.conditions = this.conditions.map(condition =>
        condition.key === activeConditionKey &&
        condition.basicCondition &&
        condition.basicCondition.familyGenderAges
          ? {
              ...condition,
              basicCondition: {
                ...condition.basicCondition,
                familyGenderAges: condition.basicCondition.familyGenderAges.filter(
                  (_: ConditionFamilyGenderAge, familyGenderAgeIndex: number) =>
                    familyGenderAgeIndex !== index
                )
              }
            }
          : condition
      );
    },

    initConditionsEnqueteAndPurchase() {
      if (this.questionCategoryList?.list?.length) {
        const newEnqueteCondition = initEnqueteCondition(this.enqueteKey);
        this.conditions = this.conditions.map(condition => ({
          ...condition,
          enqueteCondition: newEnqueteCondition,
          enqueteVModel: initEnqueteVModel(this.questionCategoryList?.list)
        }));
      }
      if (this.purchaseLogCategoryList?.list?.length) {
        const newPurchaseCondition = initPurchaseCondition(this.purchaseKey);
        this.conditions = this.conditions.map(condition => ({
          ...condition,
          purchaseCondition: newPurchaseCondition,
          purchaseVModel: initPurchaseVModel(this.purchaseLogCategoryList?.list)
        }));
      }
    },
    updateEnqueteCondition(value: {
      categoryId: number;
      questionId: number;
      topicCode: string;
      optionCode: string;
      checked: boolean;
      not?: boolean;
    }) {
      this.conditions = this.conditions.map(condition => ({
        ...condition,
        enqueteVModel:
          condition.key === this.activeConditionKey
            ? updateEnqueteVModel(condition.enqueteVModel, value)
            : condition.enqueteVModel
      }));
    },
    updatePurchaseCondition(value: {
      largeSegmentCode?: string;
      middleSegmentCode?: string;
      minorSegmentCode?: string;
      consumerKey?: consumerType;
      checked: boolean;
    }) {
      this.conditions = this.conditions.map(condition => ({
        ...condition,
        purchaseVModel:
          condition.key === this.activeConditionKey
            ? updatePurchaseVModel(condition.purchaseVModel, value)
            : condition.purchaseVModel
      }));
    },
    updateActiveEnqueteCategory(category: { id: number; label: string }) {
      this.activeEnqueteCategory = category;
    },
    updateActivePurchaseCategory(category: { id: number; label: string }) {
      this.activePurchaseCategory = category;
    },
    resetEnqueteCategory() {
      this.activeEnqueteCategory = initialEnqueteCategory;
    },
    resetPurchaseCategory() {
      this.activePurchaseCategory = initialPurchaseCategory;
    },
    setSample(
      initialSamples: NumberOfSamplesInitialAreaSamples,
      otherSamples: NumberOfSamplesInitialAreaSamples
    ) {
      this.sampleCount = '';
      this.checkedSample = false;
      this.isSmallSample = true;
      const samples = otherSamples.list.map(v => v.number);
      const sample = max(samples);
      if (sample) {
        if (sample >= smallSampleSize) this.isSmallSample = false;
        if (sample > 0) this.checkedSample = true;
      }
      this.isDisplaySample = true;
      this.initialSamples = initialSamples.list;
      this.contractedSamples = otherSamples.list;
    },
    resetSample() {
      this.checkedSample = false;
      this.isDisplaySample = false;
      this.isSmallSample = false;
    },
    checkEnqueteValue(enquete: {
      answerType: string;
      categoryId: number;
      questionId: number;
      topicCode: string;
      optionCode: string;
      checked: boolean;
      not?: boolean;
    }) {
      if (enquete.checked) {
        const isCheckedTopic = this.activeCondition?.enqueteVModel.some(enq =>
          enq.questions.some(question =>
            question.topics.some(topic =>
              topic.options.some(option => {
                return (
                  topic.id === enquete.topicCode &&
                  option.id !== enquete.optionCode &&
                  option.checked
                );
              })
            )
          )
        );
        if (
          this.activeCondition?.conditionOp ===
            ConditionCardConditionOpEnum.And &&
          enquete.answerType === 'SINGLE' &&
          isCheckedTopic
        ) {
          // AND条件Conditonですでに同じシングルアンサー属性アンケートの設問の選択肢が存在している場合
          this.errorModal = {
            isShow: true,
            title: '単一回答です',
            message:
              '単一回答の設問は「AND」指定されたカードに複数の選択肢を設定できません。'
          };
        }
        if (!this.errorModal.isShow) {
          const isPurchaseChecked = this.activeCondition?.purchaseVModel.some(
            large =>
              large.middleClasses.some(middle =>
                middle.minorClasses.some(minor =>
                  minor.consumerClasses.some(consumer => consumer.checked)
                )
              )
          );
          if (isPurchaseChecked) {
            // 購買ログが設定済みのConditonにアンケートが追加された場合
            this.errorModal = {
              isShow: true,
              title: '新しいカードを作成してください',
              message:
                'アンケートと購買ログは同じカードに設定できません。新しいカードを作成し条件設定してください'
            };
          }
        }

        if (!this.errorModal.isShow) {
          return;
        } else {
          // 追加したアンケートをConditonから削除する
          enquete.checked = false;
        }
      }

      this.updateEnqueteCondition(enquete);
    },
    checkPurcaseValue(purchase: {
      largeSegmentCode?: string;
      middleSegmentCode?: string;
      minorSegmentCode?: string;
      consumerKey?: consumerType;
      checked: boolean;
    }) {
      const isCheckedTopic = this.activeCondition?.purchaseVModel.some(pur =>
        pur.middleClasses.some(middle =>
          middle.minorClasses.some(minor =>
            minor.consumerClasses.some(consumer => {
              return (
                purchase.minorSegmentCode === minor.segmentCode &&
                purchase.consumerKey !== consumer.consumerClass &&
                consumer.checked
              );
            })
          )
        )
      );
      if (
        this.activeCondition?.conditionOp ===
          ConditionCardConditionOpEnum.And &&
        isCheckedTopic
      ) {
        // AND条件Conditonですでに同じ購買ログの設問の選択肢が存在している場合
        this.errorModal = {
          isShow: true,
          title: '単一回答です',
          message:
            '単一回答の設問は「AND」指定されたカードに複数の選択肢を設定できません。'
        };
      }
      if (!this.errorModal.isShow) {
        const isEnueteChecked = this.activeCondition?.enqueteVModel.some(
          model =>
            model.questions.some(question =>
              question.topics.some(topic =>
                topic.options.some(topic => topic.checked)
              )
            )
        );
        if (isEnueteChecked) {
          // アンケートが設定済みのConditonに購買ログが追加された場合
          this.errorModal = {
            isShow: true,
            title: '新しいカードを作成してください',
            message:
              'アンケートと購買ログは同じカードに設定できません。新しいカードを作成し条件設定してください'
          };
        }
      }

      if (!this.errorModal.isShow) {
        return;
      }

      // 追加した購買ログをConditonから削除する
      purchase.checked = false;
      this.updatePurchaseCondition(purchase);
    }
  },
  getters: {
    condition: state => (
      conditionKey: string | undefined
    ): ConditionCardWithKey | undefined => {
      return state.conditions.find(condition => condition.key === conditionKey);
    },
    activeCondition: state => {
      return state.conditions.find(
        condition => condition.key === state.activeConditionKey
      );
    },
    cardConditionForm: state => {
      let _cardCondition = '0';
      for (let i = 1; i < state.conditions.length; i++) {
        const condition = state.conditions[i];
        const op = condition.op ? `${condition.op.toLowerCase()}` : '';
        _cardCondition += `-${op}-${i}`;
      }
      return _cardCondition;
    },
    conditionCardsForm: state => {
      const conditionCards: ConditionCardForm[] = state.conditions.map(
        condition => {
          const checkedList = condition.enqueteVModel.filter(enquete =>
            enquete.questions.some(question =>
              question.topics.some(topic =>
                topic.options.some(option => option.checked)
              )
            )
          );
          const enquetes: CustomTargetEnqueteTopicForm[] = [];
          checkedList.forEach(checked => {
            checked.questions.map(q => {
              q.topics.map(topic => {
                enquetes.push({
                  id: topic.id,
                  multiple: topic.multiple,
                  options: topic.options
                    .filter(option => option.checked)
                    .map(option => {
                      return {
                        id: option.id,
                        checked: option.checked,
                        not: option.not ?? null
                      };
                    })
                });
              });
            });
          });
          const PurchaseCheckedList = condition.purchaseVModel.filter(large =>
            large.middleClasses.some(middle =>
              middle.minorClasses.some(minor =>
                minor.consumerClasses.some(consumer => consumer.checked)
              )
            )
          );
          const purchase: CustomTargetPurchaseSegmentForm[] = [];
          PurchaseCheckedList.forEach(checked => {
            checked.middleClasses.map(middle => {
              middle.minorClasses.map(minor => {
                purchase.push({
                  minorClassSegmentCode: minor.segmentCode,
                  consumerClasses: minor.consumerClasses
                    .filter(consumer => consumer.checked)
                    .map(consumer => ({
                      consumerClass: convertToConsumer(consumer.consumerClass)
                    }))
                });
              });
            });
          });
          const basicCondition = condition.basicCondition;
          let basicConditionForm:
            | CustomTargetBasicCondition
            | undefined = undefined;
          if (basicCondition) {
            const genderAges = basicCondition.genderAges
              ?.map(gender => {
                let genderAgeFilters = gender.genderAgeFilters.filter(
                  fil => fil.value !== ''
                );
                const greaterValue = genderAgeFilters?.find(
                  gen =>
                    gen.op === ConditionGenderAgeFilterOpEnum.GreaterThanOrEqual
                )?.value;
                const lessValue = genderAgeFilters?.find(
                  gen =>
                    gen.op === ConditionGenderAgeFilterOpEnum.LessThanOrEqual
                )?.value;
                if (!greaterValue || !lessValue) {
                  genderAgeFilters = genderAgeFilters.filter(
                    gen =>
                      gen.op !==
                        ConditionGenderAgeFilterOpEnum.GreaterThanOrEqual &&
                      gen.op !== ConditionGenderAgeFilterOpEnum.LessThanOrEqual
                  );
                }
                return { genderAgeFilters: genderAgeFilters };
              })
              .filter(gen => gen.genderAgeFilters.length > 0);
            const familyGenderAges = basicCondition.familyGenderAges
              ?.map(gender => {
                let familyGenderAgeFilters = gender.familyGenderAgeFilters.filter(
                  fil => fil.value !== ''
                );
                const greaterValue = familyGenderAgeFilters?.find(
                  gen =>
                    gen.op ===
                    ConditionFamilyGenderAgeFilterOpEnum.GreaterThanOrEqual
                )?.value;
                const lessValue = familyGenderAgeFilters?.find(
                  gen =>
                    gen.op ===
                    ConditionFamilyGenderAgeFilterOpEnum.LessThanOrEqual
                )?.value;
                if (!greaterValue || !lessValue) {
                  familyGenderAgeFilters = familyGenderAgeFilters.filter(
                    gen =>
                      gen.op !==
                        ConditionFamilyGenderAgeFilterOpEnum.GreaterThanOrEqual &&
                      gen.op !==
                        ConditionFamilyGenderAgeFilterOpEnum.LessThanOrEqual
                  );
                }
                return {
                  familyGenderAgeFilters: familyGenderAgeFilters
                };
              })
              .filter(gen => gen.familyGenderAgeFilters.length > 0);
            basicConditionForm = {
              basicTargetIds:
                basicCondition.basicTargetIds?.length === 0
                  ? undefined
                  : basicCondition.basicTargetIds,
              genderAges:
                basicCondition.genderAges?.length === 0
                  ? undefined
                  : genderAges?.length === 0
                  ? undefined
                  : genderAges,
              occupation: basicCondition.occupation?.includes(0)
                ? undefined
                : basicCondition.occupation,
              married: basicCondition.married,
              familyGenderAges:
                basicCondition.familyGenderAges?.length === 0
                  ? undefined
                  : familyGenderAges?.length === 0
                  ? undefined
                  : familyGenderAges
            };
          }
          const enqueteConditionForm: CustomTargetEnqueteConditionForm = {
            enqueteKey: state.enqueteKey ?? '',
            enquetes: enquetes.filter(e => e.options.length > 0)
          };
          const purchaseConditionForm: CustomTargetPurchaseConditionForm = {
            purchaseKey: state.purchaseKey ?? '',
            purchaseSegments: purchase.filter(p => p.consumerClasses.length > 0)
          };
          let conditionOp: ConditionCardFormConditionOpEnum;
          switch (condition.conditionOp) {
            case ConditionCardConditionOpEnum.And:
              conditionOp = ConditionCardFormConditionOpEnum.And;
              break;
            case ConditionCardConditionOpEnum.Or:
              conditionOp = ConditionCardFormConditionOpEnum.Or;
              break;
          }
          return {
            conditionOp: conditionOp,
            basicCondition:
              basicConditionForm && Object.keys(basicConditionForm).length === 0
                ? undefined
                : basicConditionForm,
            enqueteCondition:
              enqueteConditionForm.enquetes?.length === 0
                ? undefined
                : enqueteConditionForm,
            purchaseCondition:
              purchaseConditionForm.purchaseSegments?.length === 0
                ? undefined
                : purchaseConditionForm
          };
        }
      );
      return conditionCards;
    },
    completedConditions: state => {
      if (!state.customTarget || !state.customTarget.conditionCards) return [];
      state.customTarget.conditionCards.sort((a, b) => a.id - b.id);
      return state.customTarget.conditionCards?.map(condition => ({
        id: condition.id,
        operand: condition.op,
        conditionOp: condition.conditionOp,
        filters: completedConditionTags(
          condition,
          state.questionCategoryFullList,
          state.purchaseLogCategoryFullList
        )
      }));
    },
    isEmpty() {
      return (conditionKey: string) => {
        const basicCondition = this.condition(conditionKey)?.basicCondition;
        const enqueteVModel = this.condition(conditionKey)?.enqueteVModel;
        const purchaseVModel = this.condition(conditionKey)?.purchaseVModel;

        if (!basicCondition || !enqueteVModel || !purchaseVModel) return true;

        const isBasicConditionEmpty =
          basicCondition.basicTargetIds?.length === 0 &&
          basicCondition.genderAges?.every(
            (genderAge: ConditionGenderAge, index: number) => {
              const from = genderAge.genderAgeFilters.find(
                (value: ConditionGenderAgeFilter) =>
                  value.op === ConditionGenderAgeFilterOpEnum.GreaterThanOrEqual
              );
              const to = genderAge.genderAgeFilters.find(
                (value: ConditionGenderAgeFilter) =>
                  value.op === ConditionGenderAgeFilterOpEnum.LessThanOrEqual
              );
              const gender = genderAge.genderAgeFilters.find(
                (value: ConditionGenderAgeFilter) => value.field === 'gender'
              );
              const validAge = this.validateAge(conditionKey, index);
              if (gender?.value !== '') return false;
              if (!validAge.from || !validAge.to) {
                return true;
              }
              if (to?.value && from?.value === '') {
                return true;
              }
              if (from?.value && to?.value === '') {
                return true;
              }
              return to?.value === '' && from?.value === '';
            }
          ) &&
          basicCondition.occupation?.includes(0) &&
          basicCondition.married === undefined &&
          basicCondition.familyGenderAges?.every(
            (genderAge: ConditionFamilyGenderAge, index: number) => {
              const from = genderAge.familyGenderAgeFilters.find(
                (value: ConditionFamilyGenderAgeFilter) =>
                  value.op ===
                  ConditionFamilyGenderAgeFilterOpEnum.GreaterThanOrEqual
              );
              const to = genderAge.familyGenderAgeFilters.find(
                (value: ConditionFamilyGenderAgeFilter) =>
                  value.op ===
                  ConditionFamilyGenderAgeFilterOpEnum.LessThanOrEqual
              );
              const gender = genderAge.familyGenderAgeFilters.find(
                (value: ConditionFamilyGenderAgeFilter) =>
                  value.field === 'familyGender'
              );
              const validAge = this.validateFamilyAge(conditionKey, index);
              if (gender?.value !== '') return false;
              if (!validAge.from || !validAge.to) {
                return true;
              }
              if (to?.value && from?.value === '') {
                return true;
              }
              if (from?.value && to?.value === '') {
                return true;
              }
              return to?.value === '' && from?.value === '';
            }
          );

        const isEnqueteEmpty = enqueteVModel.every(enquetes =>
          enquetes.questions?.every(question =>
            question.topics?.every(topic =>
              topic.options?.every(option => !option.checked)
            )
          )
        );
        const isPurchaseEmpty = purchaseVModel.every(p =>
          p.middleClasses?.every(middle =>
            middle.minorClasses?.every(minor =>
              minor.consumerClasses?.every(consumer => !consumer.checked)
            )
          )
        );
        return isBasicConditionEmpty && isEnqueteEmpty && isPurchaseEmpty;
      };
    },
    isValid(): boolean {
      return (
        this.conditions
          .filter(
            condition =>
              condition.basicCondition?.genderAges &&
              condition.basicCondition?.genderAges.length > 0
          )
          .every(condition => {
            return condition.basicCondition?.genderAges?.every(
              (_: ConditionGenderAge, index: number) => {
                const valid = this.validateAge(condition.key, index);
                return valid.from && valid.to;
              }
            );
          }) &&
        this.conditions
          .filter(
            condition =>
              condition.basicCondition?.familyGenderAges &&
              condition.basicCondition?.familyGenderAges.length > 0
          )
          .every(condition => {
            return condition.basicCondition?.familyGenderAges?.every(
              (_: ConditionFamilyGenderAge, index: number) => {
                const valid = this.validateFamilyAge(condition.key, index);
                return valid.from && valid.to;
              }
            );
          })
      );
    },
    validateAge(): (
      conditionKey: string,
      index: number
    ) => { from: boolean; to: boolean } {
      return (conditionKey, index) => {
        let valid = { from: true, to: true };

        const genderAges = this.condition(conditionKey)?.basicCondition
          ?.genderAges;

        if (!genderAges) return valid;

        const from = genderAges[index].genderAgeFilters.find(
          (field: ConditionGenderAgeFilter) =>
            field.op === ConditionGenderAgeFilterOpEnum.GreaterThanOrEqual
        )?.value;
        const to = genderAges[index].genderAgeFilters.find(
          (field: ConditionGenderAgeFilter) =>
            field.op === ConditionGenderAgeFilterOpEnum.LessThanOrEqual
        )?.value;

        // from 及び to は、4歳以上を許容する
        if (typeof from === 'string' && from !== '' && Number(from) < 4) {
          valid = { ...valid, from: false };
        }
        if (typeof to === 'string' && to !== '' && Number(to) < 4) {
          valid = { ...valid, to: false };
        }

        if (typeof from === 'string' && !from.match(/^([0-9]?[0-9])?$/)) {
          valid = { ...valid, from: false };
        }
        if (typeof to === 'string' && !to.match(/^([0-9]?[0-9])?$/)) {
          valid = { ...valid, to: false };
        }

        if (from && to && Number(from) > Number(to)) {
          valid = { from: false, to: false };
        }

        return valid;
      };
    },
    validateFamilyAge(): (
      conditionKey: string,
      index: number
    ) => { from: boolean; to: boolean } {
      return (conditionKey, index) => {
        let valid = { from: true, to: true };

        const genderAges = this.condition(conditionKey)?.basicCondition
          ?.familyGenderAges;

        if (!genderAges) return valid;

        const from = genderAges[index].familyGenderAgeFilters.find(
          (field: ConditionFamilyGenderAgeFilter) =>
            field.op === ConditionFamilyGenderAgeFilterOpEnum.GreaterThanOrEqual
        )?.value;
        const to = genderAges[index].familyGenderAgeFilters.find(
          (field: ConditionFamilyGenderAgeFilter) =>
            field.op === ConditionFamilyGenderAgeFilterOpEnum.LessThanOrEqual
        )?.value;

        // from 及び to は、0歳以上を許容する
        if (typeof from === 'string' && from !== '' && Number(from) < 0) {
          valid = { ...valid, from: false };
        }
        if (typeof to === 'string' && to !== '' && Number(to) < 0) {
          valid = { ...valid, to: false };
        }

        if (typeof from === 'string' && !from.match(/^([0-9]?[0-9])?$/)) {
          valid = { ...valid, from: false };
        }
        if (typeof to === 'string' && !to.match(/^([0-9]?[0-9])?$/)) {
          valid = { ...valid, to: false };
        }

        if (from && to && Number(from) > Number(to)) {
          valid = { from: false, to: false };
        }

        return valid;
      };
    },
    filterdQuestionCategories(): QuestionCategory[] {
      if (this.activeEnqueteCategory.id) {
        return (
          this.questionCategoryList?.list?.filter(
            category =>
              category.questionCategoryId === this.activeEnqueteCategory.id
          ) || []
        );
      } else {
        return this.questionCategoryList?.list || [];
      }
    },
    filterdPurchaseCategories(): PurchaseLogLargeCategory[] {
      if (this.activePurchaseCategory.id) {
        return (
          this.purchaseLogCategoryList?.list?.filter(
            category =>
              category.segmentCode === this.activePurchaseCategory.id.toString()
          ) || []
        );
      } else {
        return this.purchaseLogCategoryList?.list || [];
      }
    },
    // カスタムターゲット一覧に表示するデータ
    customTargetList: state =>
      state.customTargets.map(customTarget => {
        return {
          name: customTarget.name,
          description: {
            caption: customTarget.description,
            conditions: customTarget.conditionCards
              ? customTarget.conditionCards?.map((condition, index) => ({
                  op: condition.op,
                  label: `条件${index + 1}`,
                  tooltipText: getTooltipText(
                    condition,
                    state.questionCategoryList,
                    state.purchaseLogCategoryList
                  )
                }))
              : []
          },
          lastUpdaterId: customTarget.modifierAccountId,
          lastUpdateDate: customTarget.modifiedAt || customTarget.createdAt,
          actions: { name: customTarget.name, id: customTarget.id }
        };
      }) || [],

    // アンケート検索結果のトピック数
    enqueteTopicsCount: state => {
      const enqueteTopics =
        state.questionCategoryList?.list?.reduce((all, item) => {
          const topics = item.questions?.reduce((_topics, question) => {
            if (question.topics) {
              _topics = [..._topics, ...question.topics];
            }
            return _topics;
          }, [] as QuestionTopic[]);

          if (topics) {
            all = [...all, ...topics];
          }
          return all;
        }, [] as QuestionTopic[]) || [];
      return enqueteTopics.length;
    },
    purchaseMinorClassesCount: state => {
      const purchaseMinorClasses =
        state.purchaseLogCategoryList?.list?.reduce((allMinorClasses, item) => {
          const minorClasses = item.middleClasses?.reduce(
            (_minorClasses, middleClass) => {
              if (middleClass.minorClasses) {
                _minorClasses = [..._minorClasses, ...middleClass.minorClasses];
              }
              return _minorClasses;
            },
            [] as PurchaseLogMinorCategory[]
          );

          if (minorClasses) {
            allMinorClasses = [...allMinorClasses, ...minorClasses];
          }
          return allMinorClasses;
        }, [] as PurchaseLogMinorCategory[]) || [];
      return purchaseMinorClasses.length;
    },
    // ConditionのAND可不可リスト
    isAndDisabledList: state => {
      const list: {
        key: string;
        isAndDisabled: boolean;
        Op: ConditionCardConditionOpEnum;
      }[] = [];
      for (const condition of state.conditions) {
        let isAndDisabled = false;
        // アンケートの同一設問(シングルアンサー属性)内の選択肢が複数存在するか
        isAndDisabled = isAndDisabled
          ? isAndDisabled
          : condition.enqueteVModel.some(enq =>
              enq.questions.some(question =>
                question.topics.some(topic => {
                  const checkedTopics = topic.options.filter(
                    option => option.checked === true
                  );
                  return checkedTopics.length > 1 && topic.multiple === false;
                })
              )
            );
        // 購買ログの同一設問での選択肢が複数存在するか
        isAndDisabled = isAndDisabled
          ? isAndDisabled
          : condition.purchaseVModel.some(large =>
              large.middleClasses.some(middle =>
                middle.minorClasses.some(minor => {
                  const checkedConsumer = minor.consumerClasses.filter(
                    consumer => consumer.checked === true
                  );
                  return checkedConsumer.length > 1;
                })
              )
            );

        list.push({
          key: condition?.key,
          isAndDisabled,
          Op: condition.conditionOp
        });
      }
      return list;
    },
    // Conditionに利用不可条件が含まれているか
    isInvalidCard: state => {
      return (
        state.conditions.some(condition =>
          condition.enqueteVModel.some(enquete =>
            enquete.questions.some(question =>
              question.topics.some(topic =>
                topic.options.some(option => option.invalid && option.checked)
              )
            )
          )
        ) ||
        state.conditions.some(condition =>
          condition.purchaseVModel.some(purchase =>
            purchase.middleClasses.some(middle =>
              middle.minorClasses.some(minor =>
                minor.consumerClasses.some(
                  consumer => consumer.invalid && consumer.checked
                )
              )
            )
          )
        )
      );
    }
  }
});
