import {
  ConditionCard,
  CustomTargetBasicCondition,
  CustomTargetEnqueteCondition,
  CustomTargetPurchaseCondition,
  PurchaseLogCategoryList,
  QuestionCategoryList
} from '@/api/openapi';
import { OCCUPATIONS } from '@/common/constant';
import { useCustomTargetTag } from '@/composables/customTarget';
import { useBasicTargetsStore } from '../basicTargets';
import {
  getInvalidCustomTargetEnquete,
  isCheckedEnquete,
  isNotEnquete
} from './enqueteCondition';
import {
  getInvalidCustomTargetPurchase,
  isCheckedPurchase,
  makePurchaseCardLablel
} from './purchaseCondition';
import { consumerType, Tag, TagValue } from './type';

/**
 * タグ表示用
 */

export const completedConditionTags = (
  condition: ConditionCard,
  questionCategoryList?: QuestionCategoryList,
  purchaseLogCategoryList?: PurchaseLogCategoryList
): Tag[] => {
  return [
    ...basicConditionTags(condition.basicCondition),
    ...enqueteConditionTags(condition.enqueteCondition, questionCategoryList),
    ...purchaseConditionTags(
      condition.purchaseCondition,
      purchaseLogCategoryList
    )
  ];
};

const basicConditionTags = (
  basicCondition?: CustomTargetBasicCondition | null
): Tag[] => {
  let tags: Tag[] = [];
  if (basicCondition === null) {
    return tags;
  }
  const basicTargetStore = useBasicTargetsStore();
  const {
    generateGenderAgeTag,
    generateFamilyGenderAgeTag
  } = useCustomTargetTag();
  if (basicCondition) {
    const _basicTargetTags: TagValue[] = basicCondition.basicTargetIds
      ? basicCondition.basicTargetIds.map(basicTargetId => ({
          label:
            basicTargetStore.basicTargetLabels.find(
              label => label.id === basicTargetId
            )?.label || ''
        }))
      : [];
    const manualSettingTags: TagValue[] = basicCondition.genderAges
      ? basicCondition.genderAges.map(genderAgeValues => ({
          label: generateGenderAgeTag(genderAgeValues.genderAgeFilters)
        }))
      : [];
    const basicTargetTags = {
      id: 'basicTarget',
      label: '性／年齢',
      tags: [
        ..._basicTargetTags,
        // 手動設定
        ...manualSettingTags
      ]
    };
    if (basicTargetTags.tags.length > 0) {
      tags = [...tags, basicTargetTags];
    }
    const occupationTags = {
      id: 'occupation',
      label: '職業',
      tags: basicCondition.occupation
        ? basicCondition.occupation
            .map(occupationId => ({
              label:
                OCCUPATIONS.find(label => label.id === occupationId)?.label ||
                ''
            }))
            .filter(tag => !!tag.label)
        : []
    };
    if (occupationTags.tags.length > 0) {
      tags = [...tags, occupationTags];
    }
    const married = basicCondition.married;
    if (typeof married !== 'undefined' && married !== null) {
      tags = [
        ...tags,
        {
          id: 'married',
          label: '未既婚',
          tags: [{ label: married ? '既婚' : '未婚' }]
        }
      ];
    }
    const familySettingTags: TagValue[] = basicCondition.familyGenderAges
      ? basicCondition.familyGenderAges.map(genderAgeValues => ({
          label: generateFamilyGenderAgeTag(
            genderAgeValues.familyGenderAgeFilters
          )
        }))
      : [];
    const familyTags = {
      id: 'family',
      label: '同居家族の年齢/性別',
      tags: [...familySettingTags]
    };
    if (familyTags.tags.length > 0) {
      tags = [...tags, familyTags];
    }
    tags = [...tags];
  }
  return tags;
};

export const enqueteConditionTags = (
  enqueteCondition?: CustomTargetEnqueteCondition | null,
  questionCategoryList?: QuestionCategoryList
): Tag[] => {
  let tags: Tag[] = [];
  if (!questionCategoryList || !enqueteCondition) return tags;
  if (!questionCategoryList.list) return tags;
  for (const category of questionCategoryList.list) {
    if (category.questions) {
      for (const question of category.questions) {
        if (question.topics) {
          for (const topic of question.topics) {
            const _checkedOptions =
              topic.options
                ?.filter(option =>
                  isCheckedEnquete(
                    enqueteCondition.enquetes,
                    topic.topicCode,
                    option.optionCode
                  )
                )
                .map(option => ({
                  label: option.optionText || ''
                })) || [];
            const _notOptions =
              topic.options
                ?.filter(option =>
                  isNotEnquete(
                    enqueteCondition.enquetes,
                    topic.topicCode,
                    option.optionCode
                  )
                )
                .map(option => ({
                  label: option.optionText || '',
                  not: true
                })) || [];
            if (_checkedOptions.length > 0 || _notOptions.length > 0) {
              tags = [
                ...tags,
                {
                  id: topic.topicCode || '',
                  label: topic.topicText || '',
                  tags: [..._checkedOptions, ..._notOptions]
                }
              ];
            }
          }
        }
      }
    }
  }

  getInvalidCustomTargetEnquete(
    enqueteCondition.enquetes,
    questionCategoryList.list
  ).map(invalid => {
    if (tags.some(tag => tag.id === invalid.topicCode.value)) {
      tags.map(tag => ({
        ...tag,
        tags:
          tag.id === invalid.topicCode.value
            ? invalid.optionCodes.map(option => {
                tag.tags.push({
                  label: option.optionCodeValues[0].optionText ?? '',
                  not: true
                });
              })
            : tag.tags
      }));
    } else {
      tags = [
        ...tags,
        {
          id: invalid.topicCode.value || '',
          label: invalid.topicCode.topicText || '',
          tags: invalid.optionCodes.map(option => ({
            label: option.optionCodeValues[0].optionText || ''
          }))
        }
      ];
    }
  });

  return tags;
};

export const purchaseConditionTags = (
  purchaseCondition?: CustomTargetPurchaseCondition | null,
  purchaseLogCategoryList?: PurchaseLogCategoryList
): Tag[] => {
  let tags: Tag[] = [];
  if (!purchaseLogCategoryList || !purchaseCondition) return tags;
  if (!purchaseLogCategoryList.list) return tags;
  for (const category of purchaseLogCategoryList.list) {
    if (category.middleClasses) {
      for (const middle of category.middleClasses) {
        if (middle.minorClasses) {
          for (const minor of middle.minorClasses) {
            const _checkedOptions =
              Object.values(consumerType)
                .filter(consumersKey =>
                  isCheckedPurchase(
                    purchaseCondition.purchaseSegments,
                    minor.segmentCode,
                    consumersKey as consumerType
                  )
                )
                .map(consumer => ({
                  label: makePurchaseCardLablel(consumer) || ''
                })) || [];
            if (_checkedOptions.length > 0) {
              tags = [
                ...tags,
                {
                  id: minor.segmentCode || '',
                  label: minor.segmentName || '',
                  tags: [..._checkedOptions]
                }
              ];
            }
          }
        }
      }
    }
  }

  getInvalidCustomTargetPurchase(
    purchaseCondition,
    purchaseLogCategoryList?.list
  ).map(invalid => {
    tags = [
      ...tags,
      {
        id: invalid.minorClassSegmentCode || '',
        label: invalid.minorClassSegmentName || '',
        tags: invalid.consumerClasses.map(consumer => ({
          label: makePurchaseCardLablel(consumer.consumerClass) || ''
        }))
      }
    ];
  });

  return tags;
};
