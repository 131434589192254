<template>
  <LayoutWithSidebar :breadcrumbs="breadcrumbs" title="作成完了">
    <Card class="mb-6">
      <Typography size="xxl" bold>
        {{ store.customTarget?.name }}
      </Typography>
    </Card>
    <template
      v-for="condition in store.completedConditions"
      :key="condition.id"
    >
      <Flex v-if="condition.operand" justify-center class="mt-4">
        <div class="custom-target-op">
          <Typography color="white">
            {{ condition.operand }}
          </Typography>
        </div>
      </Flex>
      <Card class="custom-target-card mt-4">
        <div class="custom-target-card-operand">
          <Typography size="l" color="white">
            {{ condition.conditionOp }}
          </Typography>
        </div>
        <Box margin-top="-16px">
          <template v-for="filter in condition.filters" :key="filter.id">
            <div class="mt-4">
              <Label bold size="m">
                {{ filter.label }}
              </Label>
              <Flex flex-wrap class="mt-3">
                <Box margin-left="-4px">
                  <template v-for="tag in filter.tags" :key="tag">
                    <Tag
                      :label="tag.label"
                      :variant="tag.not ? 'outlined' : undefined"
                      class="ma-1"
                      read-only
                    />
                  </template>
                </Box>
              </Flex>
            </div>
          </template>
        </Box>
      </Card>
    </template>
    <Flex class="mt-12" justify-center align-center>
      <Button
        size="l"
        variant="outlined"
        width="114px"
        class="mx-2"
        @click="returnEdit"
      >
        戻って編集
      </Button>
      <Button size="l" width="114px" class="mx-2" @click="goToList">
        一覧へ
      </Button>
    </Flex>
  </LayoutWithSidebar>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { useCustomTargetStore } from '@/store/customTarget/';
import { COMPANY_ROUTES, CUSTOM_ROUTES } from '@/router';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import Label from '@/components/ui/Label.vue';
import Tag from '@/components/ui/Tag.vue';
import Typography from '@/components/ui/Typography.vue';

const breadcrumbs = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: CUSTOM_ROUTES.index, label: 'カスタムターゲット' }
  ],
  current: { label: '作成完了' }
};
const router = useRouter();
const {
  params: { companyId }
} = useRoute();
const store = useCustomTargetStore();
const goToList = () => {
  router.push({ name: CUSTOM_ROUTES.index, params: { companyId } });
};
const returnEdit = () => {
  router.push({
    name: CUSTOM_ROUTES.edit,
    params: { id: store.customTarget.id, companyId }
  });
};
if (!store.customTarget) {
  goToList();
}
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';
.custom-target-op {
  display: inline-block;
  background-color: var(--dark-color);
  border-radius: 100px;
  padding: $spacing-base * 2 $spacing-base * 4;
}
.custom-target-card {
  position: relative;
  &-operand {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    background-color: var(--primary-color);
    border-top-right-radius: 16px;
    padding: $spacing-base * 2 $spacing-base * 4;
  }
}
</style>
