import {
  ConditionCard,
  CustomTargetBasicCondition,
  PurchaseLogCategoryList,
  QuestionCategoryList
} from '@/api/openapi';
import { OCCUPATIONS } from '@/common/constant';
import { useCustomTargetTag } from '@/composables/customTarget';
import { useBasicTargetsStore } from '../basicTargets';
import { enqueteConditionTags, purchaseConditionTags } from './tags';

export const getTooltipText = (
  condition: ConditionCard,
  questionCategoryList?: QuestionCategoryList,
  purchaseLogCategoryList?: PurchaseLogCategoryList
): string => {
  return `
    ${basicConditionTooltipText(condition.basicCondition)}
    ${condition.basicCondition ? '<br>' : ''}
    ${enqueteConditionTags(condition.enqueteCondition, questionCategoryList)
      .map(
        condition =>
          `【${condition.label}】<br>
          ${condition.tags.map(tag => tag.label).join('、')}
          `
      )
      .join('<br>')}
    ${purchaseConditionTags(
      condition.purchaseCondition,
      purchaseLogCategoryList
    )
      .map(
        condition =>
          `【${condition.label}】<br>
          ${condition.tags.map(tag => tag.label).join('、')}
          `
      )
      .join('<br>')}
  `;
};

const basicConditionTooltipText = (
  basicCondition?: CustomTargetBasicCondition | null
): string => {
  const basicTargetStore = useBasicTargetsStore();
  const {
    generateGenderAgeTag,
    generateFamilyGenderAgeTag
  } = useCustomTargetTag();
  let tooltipText = '';
  if (!basicCondition) return tooltipText;
  if (basicCondition) {
    const occupationTexts = basicCondition.occupation
      ? basicCondition.occupation
          .map(
            occupationId =>
              OCCUPATIONS.find(label => label.id === occupationId)?.label
          )
          .join(',')
      : '';
    const basicTargetTexts = basicCondition.basicTargetIds
      ? basicCondition.basicTargetIds
          .map(
            basicTargetId =>
              basicTargetStore.basicTargetLabels.find(
                label => label.id === basicTargetId
              )?.label
          )
          .join(',')
      : '';
    const genderAgesTexts = basicCondition.genderAges
      ? basicCondition.genderAges
          .map(genderAge => generateGenderAgeTag(genderAge.genderAgeFilters))
          .join(',')
      : '';
    const married = basicCondition.married;
    const marriedText =
      typeof married === 'undefined' || married === null
        ? ''
        : married
        ? '既婚'
        : '未婚';
    const familyGenderAgesTexts = basicCondition.familyGenderAges
      ? '【同居家族の年齢/性別】<br>' +
        basicCondition.familyGenderAges
          .map(genderAge =>
            generateFamilyGenderAgeTag(genderAge.familyGenderAgeFilters)
          )
          .join(',')
      : '';

    tooltipText = [
      basicTargetTexts,
      genderAgesTexts,
      occupationTexts,
      marriedText,
      familyGenderAgesTexts
    ]
      .filter(text => !!text)
      .join('<br />');
  }
  return tooltipText;
};
